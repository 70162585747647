<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="padding: 0px; background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/Facility-Siting-min.png"
              width="792"
              height="529"
              alt=""
              title="Facility-Siting"
              class="img-responsive wp-image-12487"
          /></span>
          <div
            class="fusion-content-boxes content-boxes columns row fusion-columns-1 fusion-columns-total-1 fusion-content-boxes-1 content-boxes-icon-with-title content-left"
            data-animationoffset="100%"
            style="margin-top:10px;margin-bottom:60px;"
          >
            <div
              class="fusion-column content-box-column content-box-column content-box-column-1 col-lg-12 col-md-12 col-sm-12 fusion-content-box-hover content-box-column-last content-box-column-last-in-row"
            >
              <div
                class="col content-box-wrapper content-wrapper link-area-link-icon link-type-text icon-hover-animation-fade"
                style="background-color:rgba(255,255,255,0);"
                data-animationoffset="100%"
              >
                <div class="heading heading-with-icon icon-left">
                  <!-- PDF -->
                  <a
                    class="heading-link"
                    style="float:left;"
                    href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/ESQD-Termination-of-Exemption-Grandfathering-30-Oct-17.pdf?alt=media&token=f0d8639b-8cf7-43e9-aa9a-71b790918c3d"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><div class="icon">
                      <i
                        style="background-color:transparent;border-color:transparent;height:auto;width: 21px;line-height:normal;color:#aa212c;font-size:21px;"
                        class="fontawesome-icon fa fa-book circle-no"
                      ></i>
                    </div>
                    <h2
                      class="content-box-heading fusion-responsive-typography-calculated"
                      style="font-size: 18px; color:#aa212c; line-height: 1.28; --fontSize:18; --minFontSize:18;"
                      data-fontsize="18"
                      data-lineheight="23.04px"
                    >
                      ESQD Termination of Exemption
                    </h2></a
                  >
                </div>
              </div>
            </div>
            <div class="fusion-clearfix"></div>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Facility Siting and Design" size="large" />
        <Paragraph
          ><p>
            The objective of a siting evaluation is to protect personnel and
            facilities from explosive or other highly reactive operations
            through proper facility design or separation. We use industry and
            government accepted methodologies (e.g. IBC, DoD, ATF) for the
            evaluation of siting and the orientation of facilities to manage the
            risk posed by reactive chemicals and explosives on a plant site. We
            believe that the objective of a siting evaluation is to protect
            personnel and facilities from explosive or other highly reactive
            operations at an acceptable risk level through proper facility
            design or separation.<br />
            SMS can assist you in understanding the regulations that apply to
            explosive operations. Our experienced staff understands civilian and
            military explosives manufacturing, handling and storage regulations
            and their application. We specialize in interfacing with the
            individual regulating agencies (e.g., city, county, state, and the
            federal government). Many of the smaller agencies do not fully
            understand the regulations and require thorough education on what
            your facility will be doing and what the regulations really mean to
            you, to them, and the public they desire to protect.<br />
            Although the regulating codes attempt to cover virtually all
            situations, a particular code or set of codes tends to focus
            primarily on an assumed set of situations or operations. The UBC,
            for example, recognizes that it cannot cover all situations and
            states that “the building official may require a technical opinion
            and report to identify and develop methods of protection from the
            hazards presented by the hazardous material.” Where different
            regulatory codes appear to conflict or where one is more or less
            stringent than another, technical analysis and the focus of the code
            must be used to determine the best fit for the situation. To do
            otherwise may adversely affect operations, increase risk in the
            event of an incident/release, and could inhibit future expansion of
            your facility.<br />
            The facility siting services that SMS offers includes, but is not
            limited to:
          </p>
          <ul>
            <li>
              Development of paper or electronic submittal packages for
              explosive site plans (ESPs)
            </li>
            <li>
              ESP support and assessments:
              <ul>
                <li>
                  Review of current site plans, approval memos, operating
                  procedures, related correspondence
                </li>
                <li>QD Reduction Strategies</li>
                <li>Identify data/testing required to support ESP</li>
              </ul>
            </li>
            <li>ESP support using Explosive Safety Siting (ESS) software</li>
            <li>Identify/verify in-process hazard classifications</li>
            <li>Perform maximum credible event (MCE) analyses</li>
            <li>
              Structural response analysis and debris throw for exposed sites
              (ESs)
            </li>
            <li>Identify and verify paired PES-ES relationships</li>
            <li>
              Create or modify CAD drawings to support ESP submittal package
            </li>
            <li>
              Verification/compilation of Geospatial Information System (GIS)
              data
            </li>
            <li>GIS Support</li>
            <li>Database tied to geospatial features</li>
            <li>Create/finalize ESS databases</li>
            <li>Create final ESS-based site plan package</li>
            <li>ESS software training</li>
            <li>
              Liaison with DCMA, regulators to achieve compliance, approval
            </li>
          </ul>
          <p>
            Explosive facility siting issues include building design, facility
            configuration, barricades, and many other concerns. The damage or
            injury potential of explosions is determined by the separation
            distance between potential explosion sites (PES) and ESs; the
            ability of the PES to suppress blast overpressure, primary and
            secondary fragments, and the ability of the ES to resist explosion
            effects. Planning for the proper location and construction of PEP
            facilities and surrounding facilities exposed to PEP facilities is a
            key element of the explosives/toxic chemical site planning process.
            Proper application of these principles not only brings your facility
            into compliance but also manages and minimizes the risks in the
            event of an incident or release. This management process also
            ensures that risks above those normally accepted for PEP activities
            are identified and approved at the proper level of command.<br />
            Whether you manufacture explosives, have explosive intermediaries or
            use and store explosive materials as part of your process — we can
            help!
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Facility Siting and Design",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Facility siting and siting evaluations are designed to protect personnel and facilities from highly reactive operations. SMS can assist you in understanding regulation that govern your site, help develop and update an explosive site plan (ESP), assist you in using explosive safety siting (ESS) software, and cooperating with the DCMA."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
